/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
import {getImage, GatsbyImage} from "gatsby-plugin-image";
function _createMdxContent(props) {
  const _components = Object.assign({
    h2: "h2",
    p: "p",
    em: "em",
    blockquote: "blockquote",
    h3: "h3",
    ul: "ul",
    li: "li"
  }, _provideComponents(), props.components), {Video, Accordion, AccordionItem, AccordionItemHeading, AccordionItemButton, Beaver, AccordionItemPanel, RandomBeaver} = _components;
  if (!Accordion) _missingMdxReference("Accordion", true);
  if (!AccordionItem) _missingMdxReference("AccordionItem", true);
  if (!AccordionItemButton) _missingMdxReference("AccordionItemButton", true);
  if (!AccordionItemHeading) _missingMdxReference("AccordionItemHeading", true);
  if (!AccordionItemPanel) _missingMdxReference("AccordionItemPanel", true);
  if (!Beaver) _missingMdxReference("Beaver", true);
  if (!RandomBeaver) _missingMdxReference("RandomBeaver", true);
  if (!Video) _missingMdxReference("Video", true);
  return React.createElement(React.Fragment, null, React.createElement(GatsbyImage, {
    alt: "alt text",
    className: "image-float-right",
    image: getImage(props.data.mdx.frontmatter.embeddedImagesLocal[0]),
    style: {
      width: "500px",
      float: "right"
    }
  }), "\n", React.createElement(_components.h2, null, "The Land-Claims Process"), "\n", React.createElement(_components.p, null, "With the 1973 ", React.createElement(_components.em, null, "Calder"), " case and its recognition of Aboriginal Title, Canada can no longer deny that Indigenous land rights exist. It establishes two formal land claim processes to address Canada’s alienation of unceded lands and its noncompliance with treaties."), "\n", React.createElement(_components.p, null, "The comprehensive land claims process (often referred to as the modern treaty process) is for First Nations who never signed treaties and cases where Aboriginal Title is unsurrendered (which, in 1973, is true of the Algonquins of eastern Ontario, most of British Columbia, Quebec, the Atlantic provinces, and most of the northern territories)."), "\n", React.createElement(_components.p, null, "The specific claims process is developed to address broken or unfulfilled treaty promises –this is the process most applicable to lands in the GTA. A look at the 2010 Toronto Purchase Specific Claim illustrates the possibility for some redress but also the limitations of the current process."), "\n", React.createElement("div", {
    className: "section-break"
  }, React.createElement("img", {
    src: "/decor/diamond-rule.png"
  })), "\n", React.createElement(_components.h2, null, "The 2010 Toronto Purchase Specific Claim"), "\n", React.createElement(_components.blockquote, null, "\n", React.createElement(_components.p, null, "Why are we having to prove that we were the true owners of the land, and that they made the treaty? It should be the onus on the government to prove that they actually did purchase it, prove it to us that my ancestors were there at that meeting . . . Prove it to me that they gave all of this land away."), "\n", React.createElement(_components.p, null, "– ", React.createElement(_components.em, null, "Garry Sault, Elder, Mississaugas of the Credit First Nation")), "\n"), "\n", React.createElement(_components.p, null, "In 1986, the Mississaugas of the Credit draw on documentation first gathered by Peter Jones and Dr. Peter Edmund Jones as well as later researchers to submit the Toronto Purchase Specific Claim to the Indian Claims Commission (later replaced by the more independent Special Claims Tribunal). The Mississauga successfully argue that the agreement was not properly recorded or adequately explained to their leaders, the deed was blank, and it was not signed by the appropriate representatives of the Crown. They argue that the “confirmation” of the Purchase in 1805 greatly increased the amount of land supposedly ceded in 1787 without the knowledge of the Mississauga and included the Toronto Islands, which the Mississauga assert were not included in the 1787 agreement (they were not islands at that time, but a peninsula)."), "\n", React.createElement("div", {
    class: "video-gallery"
  }, React.createElement(Video, {
    url: "https://vimeo.com/768731729",
    posterFrame: "Stace_5.jpg",
    mode: "FULL_WIDTH",
    title: "On the Toronto Purchase Specific Claim",
    subTitle: "Gimaa Stace Laforme"
  }), React.createElement(Video, {
    url: "https://vimeo.com/768734743",
    posterFrame: "Carolyn_4.jpg",
    mode: "FULL_WIDTH",
    title: "Negotiating the Toronto Purchase Specific Claim",
    subTitle: "Carolyn King"
  })), "\n", React.createElement(_components.p, null, "Their specific claim also includes the smaller Brant Tract claim near Burlington. In both cases, the commission finds that the Crown did not provide fair compensation for the land. In 2010, the federal government agrees to pay $145 million to the Mississaugas of the Credit."), "\n", React.createElement(Video, {
    url: "https://vimeo.com/768726701",
    posterFrame: "Carolyn_5.jpg",
    mode: "FULL_WIDTH",
    title: "The Toronto Islands: A Sacred Place",
    subTitle: "Carolyn King"
  }), "\n", React.createElement(_components.p, null, "In this case, as in all specific claims, the government arbitrarily sets the cap for settlements at $150 million, although the present value of Toronto lands that were inappropriately alienated through the 1805 Toronto Purchase far exceeds that."), "\n", React.createElement(_components.blockquote, null, "\n", React.createElement(_components.p, null, "We got a $142 million for Toronto, and $3 million for the Brant Tract, . . . And then, there was the ultimatum. They kept land claims at $150 million and you settle or else you can just take us to court, take your choice."), "\n", React.createElement(_components.p, null, "– ", React.createElement(_components.em, null, "Carolyn King, former Chief and negotiator for the Mississaugas of the Credit")), "\n"), "\n", React.createElement(_components.p, null, "The Toronto Purchase Specific Claim took over two decades of on-and-off negotiations, countless hours of preparation, and generations of leadership to reach the eventual settlement."), "\n", React.createElement(_components.blockquote, null, "\n", React.createElement(_components.p, null, "We go through five prime ministers, I think, in twenty-two years . . . I don’t know how many different Chiefs we went through . . . at least ten, eleven band councils, and you can see the anger rising all the time. They just think they’re going to make a breakthrough. Okay, election called. Make another breakthrough, change in minister . . . You think you’re about to get a breakthrough, and then one of the negotiators goes, and they have to train a new person to do it. It takes twenty-two years, but I think we’re resilient people, if nothing else."), "\n", React.createElement(_components.p, null, "– ", React.createElement(_components.em, null, "Darin Wybenga, Traditional Knowledge and land-use coordinator, Mississigaus of the Credit First Nation")), "\n"), "\n", React.createElement(Video, {
    url: "https://vimeo.com/768726803",
    posterFrame: "Carolyn_6.jpg",
    mode: "FULL_WIDTH",
    title: "A Message to Torontonians",
    subTitle: "Carolyn King"
  }), "\n", React.createElement("img", {
    className: "toom council",
    src: "/tooms/tooms-council.png"
  }), "\n", React.createElement(Accordion, {
    allowZeroExpanded: "true",
    allowMultipleExpanded: "true"
  }, React.createElement(AccordionItem, null, React.createElement(AccordionItemHeading, null, React.createElement(AccordionItemButton, null, React.createElement("div", {
    className: "accordionHeader"
  }, React.createElement(Beaver, {
    beaver: "flag"
  }), React.createElement(_components.h2, null, "Activity: Redraft Treaty 13"), React.createElement(_components.h3, null, "Envision Treaty 13 as a true treaty.")))), React.createElement(AccordionItemPanel, null, React.createElement(_components.p, null, "Consider Treaty 13 (the so-called Toronto Purchase) from a Mississauga perspective. Draft a new version of this treaty, with some preliminary ideas to pitch at a city council meeting. Propose the terms of this treaty. Which parties should be included?"), React.createElement(_components.p, null, "What rights and responsibilities to the other does each party bear? What Protocols will be enacted to signify the ongoing reciprocity between treaty signatories?"))), React.createElement(AccordionItem, null, React.createElement(AccordionItemHeading, null, React.createElement(AccordionItemButton, null, React.createElement("div", {
    className: "accordionHeader"
  }, React.createElement(_components.h2, null, "Activity: Plan a Treaty Renewal"), React.createElement(_components.h3, null, "Draft an invitee list and invitation to the renewal of a local treaty.")))), React.createElement(AccordionItemPanel, null, React.createElement(RandomBeaver), React.createElement(_components.p, null, "Consider the Protocols that will accompany this treaty into the future. You have a massive budget and many possible venues in Toronto. You will be planning an event that will bring hundreds, possibly thousands, of people into conversation around the agreements that have been made here. Plan something fun, both entertaining and informative, with room for side discussions and a huge council. Consider the following:"), React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "How often will all parties meet to review and discuss the treaty?"), "\n", React.createElement(_components.li, null, "Who will attend? What can you promise them as incentives? What can they bring as gifts?"), "\n", React.createElement(_components.li, null, "What will happen? Imagine the day’s events."), "\n", React.createElement(_components.li, null, "Where will it happen? Select a venue."), "\n", React.createElement(_components.li, null, "When will it happen? What time of year? Is it an annual event, a centennial event?"), "\n", React.createElement(_components.li, null, "Why will it happen? Make a tagline for the whole event, something catchy that will attract guests to take part."), "\n")))));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}
